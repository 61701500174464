<template>
  <v-card class="login-card">
    <div class="layout column align-center">
      <img src="/static/light.png" alt="Safemod" height="50" class="mb-5" />
      <h3 class="flex">Trocar Senha</h3>
    </div>
    <v-card-text v-if="!error">
      <v-form ref="form">
        <v-text-field
          append-icon="lock"
          name="password"
          placeholder="Senha"
          type="password"
          @input="
            () => {
              this.$refs.form.validate();
            }
          "
          v-model="model.password"
          :rules="[rules.required]"
          class="form-control"
        ></v-text-field>
        <v-text-field
          append-icon="lock"
          name="confirmPassword"
          placeholder="Confirmar Senha"
          type="password"
          v-model="model.confirmPassword"
          :rules="confirmPasswordRule"
          class="form-control"
        ></v-text-field>
      </v-form>

      <div class="login-btn">
        <v-btn block color="primary" @click="reset" :loading="loading"
          >Redefinir Senha</v-btn
        >
      </div>
      <v-alert v-model="error" transition="scale-transition" type="error">
        Senha Inválida
      </v-alert>
    </v-card-text>
    <v-card-text v-else>
      <p style="text-align: center">
        {{ message }}
      </p>
    </v-card-text>
    <v-card-text class="flex">
      <p style="text-align: center">
        <a @click="redirectToLogin" class="register_link">Login</a>
      </p>
    </v-card-text>
    <message-dialog
      :show.sync="success"
      :dismiss-action="redirectToLogin"
      :message="successMessage"
      title="Oops"
    >
    </message-dialog>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import { decode } from 'jwt-simple';
import MessageDialog from '@/components/dialogs/MessageDialog';

export default {
  components: { MessageDialog },
  data: () => ({
    loading: false,
    payload: {},
    model: {
      password: '',
      confirmPassword: ''
    },
    success: false,
    successMessage: 'Sua senha foi redefinida com sucesso!',
    error: false,
    message:
      'Parece que este token de redefinição de senha expirou ou já foi usado. Por favor, repita o processo.',
    rules: {
      required: (value) => !!value || 'Campo Obrigatório'
    }
  }),
  computed: {
    confirmPasswordRule() {
      return [
        (v) => !!v || 'Campo Obrigatório',
        () =>
          this.model.confirmPassword === this.model.password ||
          'A Senha e a Confirmação de Senha precisam ser iguais'
      ];
    }
  },
  async mounted() {
    try {
      const token = this.$route.query.token.toString();
      const tokenPayload = decode(token, process.env.VUE_APP_JWT_SECRET);
      if (tokenPayload) {
        this.payload = tokenPayload;
        const isTokenValid = await this._isTokenValid({
          userId: tokenPayload.userId,
          tokenKey: tokenPayload.key
        });
        console.log(this.payload, isTokenValid);
        this.error = !(
          tokenPayload.expiresAt &&
          this.$moment.unix(tokenPayload.expiresAt) > this.$moment() &&
          isTokenValid.isValid
        );
      }
    } catch (err) {
      console.log(err);
      this.error = true;
    }
  },
  methods: {
    ...mapActions({
      _updatePassword: 'updatePassword',
      _isTokenValid: 'isTokenValid'
    }),
    async reset() {
      if (this.payload) {
        try {
          await this._updatePassword({
            _id: this.payload.userId,
            key: this.payload.key,
            password: this.model.password
          });
          this.success = true;
        } catch (err) {
          console.log(err);
        }
      }
    },
    redirectToLogin() {
      this.success = false;
      this.$router.push({ path: 'login' });
    }
  }
};
</script>
<style scoped lang="css">
input[type='file'] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.login-card {
  max-width: 450px;
  margin: 0 auto;
  box-shadow: none;
}
.logo {
  max-width: 300px;
  margin: 0 auto;
  height: auto;
}
.login-card h1 {
  color: #000;
  font-size: 32px;
  font-weight: 500;
  margin-top: 50px;
}
.login-card .form-control {
  font-size: 20px;
}
p {
  color: #000;
  font-weight: 500;
}

@media (max-width: 1440px) {
  .logo {
    max-width: 200px;
  }
  .login-card h1 {
    font-size: 20px;
    margin-top: 30px;
  }
  .login-card .form-control {
    font-size: 16px;
  }
}
</style>
